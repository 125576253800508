<template>
  <div id="app">
    <!-- Page preloader-->
    <div id="page-loader">
      <div class="cssload-container">
        <div class="cssload-speeding-wheel"></div>
      </div>
    </div>

    <div class="text-center page">
      <Header />

      <router-view></router-view>

      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './shared/components/layout/header.vue';
import Footer from './shared/components/layout/footer.vue';

export default {
  name: 'App',

  components: {
    Header,
    Footer
  },

  data: () => ({
    //
  })
};
</script>
