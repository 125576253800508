import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import VueI18n from 'vue-i18n';

import './registerServiceWorker';
import './assets/min/minify-min.css';
import './assets/vue/vue-style.css';

import es from './assets/i18n/es';
import en from './assets/i18n/en';

import VueAnalytics from 'vue-analytics';

const script = document.createElement('script');
script.type = 'text/javascript';
script.src = './js/minify-min.js';
document.body.appendChild(script);

Vue.config.productionTip = false;
Vue.use(VueMeta, { refreshOnceOnNavigation: true });
Vue.use(VueI18n);
Vue.config.productionTip = false;

const i18n = new VueI18n({
  escapeParameterHtml: true,
  locale: 'es',
  messages: {
    es: {
      lang: es
    },
    en: {
      lang: en
    }
  }
});

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-179939339-1'
});

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
