<template>
  <header class="page-header">
    <!-- RD Navbar-->
    <div class="rd-navbar-wrap" :class="{ hidden }">
      <nav
        class="rd-navbar rd-navbar-default"
        data-layout="rd-navbar-fixed"
        data-sm-layout="rd-navbar-fixed"
        data-sm-device-layout="rd-navbar-fixed"
        data-md-layout="rd-navbar-fixed"
        data-md-device-layout="rd-navbar-fixed"
        data-lg-device-layout="rd-navbar-fixed"
        :data-xl-device-layout="rdNavbarStatic"
        :data-xxl-device-layout="rdNavbarStatic"
        data-lg-layout="rd-navbar-fullwidth"
        :data-xl-layout="rdNavbarStatic"
        :data-xxl-layout="rdNavbarStatic"
        data-stick-up-clone="false"
        data-sm-stick-up="true"
        data-md-stick-up="true"
        data-lg-stick-up="true"
        data-xl-stick-up="true"
        data-xxl-stick-up="true"
        data-md-stick-up-offset="300px"
        data-lg-stick-up-offset="1px"
        data-stick-up="true"
      >
        <div class="rd-navbar-inner">
          <!-- RD Navbar Panel-->
          <div class="rd-navbar-panel mt-3 mb-3">
            <!-- RD Navbar Toggle-->
            <button id="btnMenu" class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap">
              <span></span>
            </button>
            <!-- RD Navbar Brand-->
            <div class="rd-navbar-brand">
              <ul class="rd-navbar-nav logo">
                <li>
                  <a href="/" class="info-logo-index">
                    <img
                      src="@/assets/images/gif/logo5.png"
                      class="logo-primary lazyload"
                      alt="logo corporativo - Connecting Tech People"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="rd-navbar-aside-right">
            <div id="mobileMenu" class="rd-navbar-nav-wrap">
              <!-- RD Navbar Nav-->
              <ul class="rd-navbar-nav">
                <li :class="{ active: 1 == active }">
                  <a href="/">{{ $t('lang.menu.item_1') }}</a>
                </li>
                <li :class="{ active: 2 == active }">
                  <a  @click="gaAboutUs" href="/nosotros">{{ $t('lang.menu.item_2') }}</a>
                </li>
                <li :class="{ active: 3 == active }">
                  <a @click="gaBusiness" href="/empresas">{{ $t('lang.menu.item_3') }}</a>
                </li>
                <li :class="{ active: 4 == active }">
                  <a @click="gaTalentSearch" href="/cuentanos">{{ $t('lang.menu.item_4') }}</a>
                </li>
                <li :class="{ active: 5 == active }">
                  <a @click="gaProfessional" href="/profesionales">{{ $t('lang.menu.item_5') }}</a>
                </li>
                <li :class="{ active: 6 == active }">
                  <a @click="gaJobSearch" href="/buscando">{{ $t('lang.menu.item_6') }}</a>
                </li>
                <li :class="{ active: 7 == active }">
                  <a @click="gaBlog" href="blog/">{{ $t('lang.menu.item_7') }}</a>
                </li>
                <li :class="{ active: 8 == active }">
                  <a  @click="gaContact" href="/contacto">{{ $t('lang.menu.item_8') }}</a>
                </li>
                <li class="show-media">
                  <a  href="https://twitter.com/connectingtechp " class=" fa-tag-menu fa-twitter"></a>
                  <a  href="https://www.linkedin.com/company/connecting-tech-people-sl/" class=" fa-tag-menu fa-linkedin"></a>
                </li>
                <li class="lang">
                  <a @click="changeLang('es')" :class="{ activeLang: 'es' == activeLang }">ES</a>
                  <a @click="changeLang('en')" :class="{ activeLang: 'en' == activeLang }">EN</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: () => ({
    rdNavbarStatic: 'rd-navbar-static',
    active: 1,
    hidden: false,
    activeLang: 'es'
  }),
  mounted() {
    const url = window.location.pathname;
    switch (url) {
      case '/':
        this.active = 1;
        break;
      case '/nosotros':
        this.active = 2;
        break;
      case '/empresas':
        this.active = 3;
        break;
      case '/cuentanos':
        this.active = 4;
        this.rdNavbarStatic = 'rd-navbar-static2';
        break;
      case '/profesionales':
        this.active = 5;
        break;
      case '/buscando':
        this.active = 6;
        this.rdNavbarStatic = 'rd-navbar-static2';
        break;
      case '/blog':
        this.active = 7;
        break;
      case '/contacto':
        this.active = 8;
        this.rdNavbarStatic = 'rd-navbar-static2';
        break;
      case '/legal':
        this.rdNavbarStatic = 'rd-navbar-static2';
        break;
      case '/privacidad':
        this.rdNavbarStatic = 'rd-navbar-static2';
        break;
      case '/privacy-policy':
        this.rdNavbarStatic = 'rd-navbar-static2';
        break;
      default:
        this.rdNavbarStatic = 'rd-navbar-static2';
        break;
    }

    if (url.indexOf('map') > -1) {
      setTimeout(() => {
        this.hidden = true;
      }, 100);
    }

    const navigatorLang = window.navigator.language.split('-')[0];
    const lang = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : navigatorLang;
    this.changeLang(lang);
  },
  methods: {
    gaAboutUs(){
      this.$ga.event({
        'eventCategory': 'menu',
        'eventAction':'/nosotros',
        'eventLabel': 'nosotros'
      });
    },

    gaBusiness(){
      this.$ga.event( {
        'eventCategory': 'menu',
        'eventAction': '/empresas',
        'eventLabel': 'empresas'
      });
    },

    gaContact(){
      this.$ga.event({
        'eventCategory': 'menu',
        'eventAction': '/contacto',
        'eventLabel': 'contacto'
      });
    },

    gaTalentSearch() {
      this.$ga.event({
        'eventCategory': 'menu',
        'eventAction': '/talento',
        'eventLabel': 'talento'
      });
    },

    gaProfessional() {
      this.$ga.event({
        'eventCategory': 'menu',
        'eventAction': '/profesionales',
        'eventLabel': 'profesionales'
      });
    },

    gaJobSearch() {
      this.$ga.event({
        'eventCategory': 'menu',
        'eventAction': '/empleo',
        'eventLabel': 'empleo'
      });
    },

    gaBlog() {
      this.$ga.event({
        'eventCategory': 'menu',
        'eventAction': '/blog',
        'eventLabel': 'blog'
      });
    },

    changeLang(lang) {
      sessionStorage.setItem('lang', lang);
      this.$i18n.locale = lang;
      this.activeLang = lang;

      const btnMenu = document.getElementById('btnMenu');
      btnMenu.className = 'rd-navbar-toggle toggle-original';

      const mobileMenu = document.getElementById('mobileMenu');
      mobileMenu.className = 'rd-navbar-nav-wrap toggle-original-elements';
    }
  }
};
</script>

<style>
.hidden {
  display: none;
}

.lang a {
  cursor: pointer;
}

.lang a:nth-child(2) {
  margin-left: 8px;
}

.activeLang {
  color: #2ed3ae !important;
}

@media (max-width: 991px) {
  .lang {
    display: flex;
  }

  .lang a {
    padding: 11px 5px 11px 18px !important;
    background: transparent !important;
  }
}
</style>
