import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../pages/home.vue')
  },
  {
    path: '/nosotros',
    name: 'About',
    component: () => import('../pages/about.vue')
  },
  {
    path: '/empresas',
    name: 'Company',
    component: () => import('../pages/company.vue')
  },
  {
    path: '/cuentanos',
    name: 'TellUs',
    component: () => import('../pages/tell_us.vue')
  },
  {
    path: '/profesionales',
    name: 'Professional',
    component: () => import('../pages/professional.vue')
  },
  {
    path: '/buscando',
    name: 'search',
    component: () => import('../pages/search.vue')
  },
  {
    path: '/contacto',
    name: 'contact',
    component: () => import('../pages/contact.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../pages/map.vue')
  },
  {
    path: '/mapa',
    name: 'mapa',
    component: () => import('../pages/mapa.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('../pages/legal.vue')
  },
  {
    path: '/privacidad',
    name: 'privacy',
    component: () => import('../pages/privacy.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('../pages/privacy-policy.vue')
  },
  {
    path: '/cookie-policy',
    name: 'cookie',
    component: () => import('../pages/cookie-policy.vue')
  },
  { path: '*', component: () => import('../pages/404.vue') }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
