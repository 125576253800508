import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import es from 'vuetify/es5/locale/es';

Vue.component('my-component', {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = 'es';
    }
  }
});

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  theme: {
    themes: {
      light: {
        primary: '#3dbea1',
        secundary: '#333333',
        default: '#eeeeee',
        headerDark: '#333333',
        navDark: '#212529',
        error: '#bd2130',
        white: '#ffffff'
      }
    }
  }
});
