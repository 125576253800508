<template>
  <div>
    <!-- Contact us -->
    <section class="section novi-background bg-cover bg-white parallax-scene-js" id="about"
      v-if="!hidden && !hiddenMap">
      <div class="container padding-left">
        <div class="row row-50 align-items-center justify-content-md-between">
          <div class="col-md-4 col-lg-3 row-160">
            <div class="row row-40 row-md-60 margin-top-contact">
              <div class="col-sm-4 col-md-12 col-xl-11 wow fadeInLeft">
                <article class="box-icon-classic">
                  <div class="box-icon-classic-header">
                    <div class="box-icon-classic-icon novi-icon linear-icon-apartment"></div>
                    <h5 class="box-icon-classic-title">{{ $t('lang.footer.card_1.description_1') }}</h5>
                  </div>
                  <div class="box-icon-classic-text">
                    <p>
                      <a href="https://goo.gl/maps/fp1hKWniReZKU2i57" target="_blank"
                        v-html="$t('lang.footer.card_1.description_2')"></a>
                    </p>
                    <br />
                  </div>
                </article>
              </div>
              <div class="col-sm-4 col-md-12 col-xl-11 wow fadeInLeft" data-wow-delay=".05s">
                <article class="box-icon-classic">
                  <div class="box-icon-classic-header">
                    <div class="box-icon-classic-icon novi-icon linear-icon-phone-wave"></div>
                    <h5 class="box-icon-classic-title">{{ $t('lang.footer.card_1.description_4') }}</h5>
                  </div>
                  <div class="box-icon-classic-text">
                    <a href="tel:+34600207741">
                      <p>+36 600 20 77 41</p>
                    </a>
                    <a href="tel:+34911853110">
                      <p>+34 911 85 31 10</p>
                    </a>
                  </div>
                  <br />
                </article>

              </div>
              <div class="col-sm-4 col-md-12 col-xl-11 wow fadeInLeft" data-wow-delay=".1s">
                <article class="box-icon-classic">
                  <div class="box-icon-classic-header">
                    <div class="box-icon-classic-icon novi-icon linear-icon-paper-plane"></div>
                    <h5 class="box-icon-classic-title">{{ $t('lang.footer.card_1.description_5') }}</h5>
                  </div>
                  <div class="box-icon-classic-text">
                    <a href="mailto:hola@connectingtechpeople.com">hola@connectingtechpeople.com</a>
                  </div>
                  <br />
                </article>


              </div>

              <div class="col-sm-4 col-md-12 col-xl-11 wow fadeInLeft" data-wow-delay=".1s">
                <article class="box-icon-classic">
                  <div class="box-icon-classic-header">
                    <div class="box-icon-classic-icon novi-icon fa-tag fa-twitter"></div>
                    <h5 class="box-icon-classic-title">{{ $t('lang.footer.card_1.description_6') }}</h5>
                  </div>
                  <div class="box-icon-classic-text">
                    <a href="https://twitter.com/connectingtechp ">
                      <p>https://twitter.com/connectingtechp </p>
                    </a>
                  </div>
                  <br />
                </article>
              </div>
              <div class="col-sm-4 col-md-12 col-xl-11 wow fadeInLeft" data-wow-delay=".1s"></div>
              <div class="col-sm-4 col-md-12 col-xl-11 wow fadeInLeft" data-wow-delay=".1s">
              <article class="box-icon-classic">
                  <div class="box-icon-classic-header">
                    <div class="box-icon-classic-icon novi-icon fa-tag fa-linkedin"></div>
                    <h5 class="box-icon-classic-title">{{ $t('lang.footer.card_1.description_7') }}</h5>
                  </div>
                  <div class="box-icon-classic-text">
                    <a href="https://www.linkedin.com/company/connecting-tech-people-sl/">
                      <p>https://www.linkedin.com/company/connecting-tech-people-sl/ </p>
                    </a>
                  </div>
                  <br />
                </article>
              </div>
            </div>
          </div>
          <div class="col-md-7 col-lg-6 margin-cero">
            <div class="inset-xl-left-35">
              <h3 class="wow fadeInRight">&nbsp;</h3>
              <h3 class="wow fadeInRight contact-title-margin">{{ $t('lang.footer.card_2.description_1') }}</h3>
              <h6 class="title-style-1 wow fadeInRight contact-title-margin" data-wow-delay=".05s"
                v-html="$t('lang.footer.card_2.description_2')"></h6>
              <div class="form-style-1 context-dark wow blurIn margin-xl">
                <!-- RD Mailform-->
                <FormContact />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="layer-wrap layer-1">
        <div class="layer" data-depth="0.4">
          <img class="lazyload" src="@/assets/images/fondo-parallax-original.webp" alt="Conectemos" width="694"
            height="539" />
        </div>
      </div>
    </section>

    <section class="section context-dark bg-accent section-cta" v-if="!hiddenMap">
      <div class="container-wide">
        <div class="row row-30 align-items-center">
          <div class="col-xl-12 col-xxl-8 text-xl-left wow blurIn">
            <h3 class=" ml-xl-4 botom-text">{{ $t('lang.footer.card_3.title') }}</h3>
            <h5 class=" ml-xl-4">
              {{ $t('lang.footer.card_3.subtitle') }}
            </h5>
          </div>
        </div>
      </div>
    </section>

    <!-- Page Footer-->
    <footer class="page-footer" v-if="!hiddenMap">
      <div class="container">
        <p v-html="$t('lang.footer.card_3.reserved')"></p>
        <p style="margin-top: 0px">
          <a href="/legal" target="_blank">{{ $t('lang.footer.card_3.legal') }}</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from 'vue';
import FormContact from '../form/form-contact.vue';
export default Vue.extend({
  name: 'Footer',
  data: () => ({
    hidden: false,
    hiddenMap: false
  }),

  methods: {
    gaConnectForm() {
      this.$ga.event({
        'eventCategory': 'form-contacto',
        'eventAction': 'submit',
        'eventLabel': 'contacto'
      });
    }
  },
  mounted() {
    const url = window.location.pathname;
    if (url.indexOf('contacto') > -1) {
      this.hidden = true;
    }

    if (url.indexOf('map') > -1) {
      this.hiddenMap = true;
    }
  },
  components: {
    FormContact
  }
}) ;
</script>
