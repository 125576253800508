<template>
  <div id="app">
    <form class="rd-mailform text-left" action="javascript:void(0);">
      <fieldset>
        <div class="form-wrap">
          <label class="form-label-contact" for="name">{{ $t('lang.footer.card_2.form.name') }}</label>
          <input class="form-contact" type="text" name="name" id="name" v-model="state.name" />
          <span v-if="v$.name.$error" class="textAlert">
            {{ $t('lang.form.error.nameContact') }}
          </span>
        </div>

        <div class="form-wrap " >
          <label class="form-label-contact" for="email">{{ $t('lang.footer.card_2.form.email') }}</label>
          <input class="form-contact" type="email" name="email" id="email" v-model="state.email" />
          <span v-if="v$.email.$error" class="textAlert">
            {{ $t('lang.form.error.emailContact') }}
          </span>
        </div>

        <div class="form-wrap">
          <label class="form-label-contact" for="phone">{{ $t('lang.footer.card_2.form.phone') }}</label>
          <input class="form-contact" type="text" name="phone" id="phone" v-model="state.phone" />
        </div>

        <div class="form-wrap" >
          <label class="form-label-contact" for="contact-message">{{ $t('lang.footer.card_2.form.message') }}</label>
          <input class="form-contact" id="contact-message" type="text" name="message" v-model="state.message" />
          <span v-if="v$.message.$error" class="textAlert" >
            {{ $t('lang.form.error.messageContact') }}
          </span>
        </div>

        <div class="form-wrap form-wrap-top">
          <label class="container-check ml-2">
            <div v-html="$t('lang.footer.card_2.form.recaptcha')"></div>
            <input type="checkbox" id="contact-check" v-model="state.checkbox"/>
            <div id="errorCheck"></div> 
            <span class="checkmark"></span>
            <span v-if="v$.checkbox.$error" class="textAlert">
              {{ $t('lang.form.error.messageContact') }}
            </span>
          </label>
        </div>

        <div class="form-wrap" id="captchaContactBase" style="transform: scale(0.9); left: -21px;"></div>
        <div class="form-button">
          <!-- <button class="button button-jerry button-primary" @click="submitForm"> -->
            <button class="button button-jerry button-primary" @click="submitForm"> 
            {{ $t('lang.footer.card_2.form.btn') }}
            <span class="button-jerry-line"></span>
          </button>
        </div>
        <FlashMessage :position="'left bottom'"></FlashMessage> 
      </fieldset>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email, maxLength, numeric  } from '@vuelidate/validators';
import { reactive, computed } from 'vue';
import axios from 'axios';
import Vue from 'vue';
import FlashMessage from '@smartweb/vue-flash-message';
Vue.use(FlashMessage);

export default {
  // setup: () => ({ v$: useVuelidate() }),
  setup() {
    var state = reactive({
      name: '',
      email: '',
      phone: '',
      message: '',
      checkbox: false,
    });
    const rules = computed(() => {
      return {
        name: { required },
        email: { required, email },
        phone: { maxLength: maxLength(15), numeric },
        message: { required },
        checkbox: { checked: (value) => value === true },
      };
    });
    const v$ = useVuelidate(rules, state);
    return { state, v$ };
  },

  methods: {
    errorMessage: function() {
      this.flashMessage.error({
        status: 'error',
        title: this.$t('lang.form.alert.error'),
        message: this.$t('lang.form.alert.message2'),
        icon: '/img/icons/error.svg',
        clickable: false,
        time: 5000
      });
    },

    successMessage: function() {
      this.flashMessage.success({
        status: 'success',
        title: this.$t('lang.form.alert.success'),
        message: this.$t('lang.form.alert.message1'),
        icon: '/img/icons/success.svg',
        clickable: false,
        time: 5000
      });
    },
    submitForm() {
      const response = window.grecaptcha.getResponse(window.captchaContact);
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error && response.length > 0) {
        delete this.state.checkbox;
        axios
          .post(`${process.env.VUE_APP_ROOT_API}/email`, this.state)
          .then((resp) => {
            const response = resp.data;
            if (response) {
              if (response.code == 200) {
                this.successMessage();
              } else if (response.code == 400 || resp.status == 400 || response.code == 500 || resp.status == 500)  {
                console.log('error ',response.code, resp);
                this.errorMessage();
              }
            } else {
              console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', response);

            }
            window.Swal.hideLoading();
          })
          .catch((error) => {
            this.errorMessage();
            console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde', error);
          });
      } else {
        console.log('Hubo un error en el servidor, por favor vuelva a intentarlo más tarde');
       }
    }
  }
};
</script>
